import "./App.css";
import Navbar from "./section/NavBar";
import Footer from "./section/Footer";
import React, { Suspense, lazy, useState } from "react";
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/400-italic.css";
import { ThemeProvider, styled } from "@mui/material/styles";
import { BrowserRouter, useNavigate, Routes, Route } from "react-router-dom";
import theme from "./theme/index";
import { Box, Fade, Fab, IconButton } from "@mui/material";
import { ArrowUpward, WhatsApp, Close } from "@mui/icons-material";
import PropTypes from "prop-types";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import eBeam from "./img/ebeam.png";
import MCBsPage from "./component/MCBsPage";

const AnimatedRoutes = React.lazy(() => import("./AnimatedRoutes"));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="left-end" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#434343",
    color: "white",
    maxWidth: 260,
    right: "100px",
    padding: "15px",
  },
}));

function ScrollTop(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 80, right: 22 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function App(props) {
  const navigate = useNavigate();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleReadMoreClick = () => {
    navigate("/ebeam"); // Navigate to the eBeam page
  };

  const handleFabClick = () => {
    setIsTooltipOpen((prev) => !prev); // Toggle tooltip visibility
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <Navbar />
          <Box sx={{ flex: 1 }}>
            <AnimatedRoutes />
          </Box>
          <Footer />
        </Suspense>

        {/* Floating Action Buttons Container */}
        <div>
          {/* <HtmlTooltip
            title={
              <React.Fragment>
                <IconButton
                  onClick={() => setIsTooltipOpen(false)}
                  size="small"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    position: "absolute",
                    top: 10,
                    right: 18,
                    color: "white",
                  }}
                >
                  <Close />
                </IconButton>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: { xs: "14px", md: "20px" },
                  }}
                >
                  e-Beam Wires & Cables
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    marginY: 3,
                    fontSize: { xs: "12px", md: "14px" },
                  }}
                >
                  Redefining Safety And Efficiency In the Wire and Cable
                  Industry
                </Typography>
                <Button onClick={handleReadMoreClick} variant="contained">
                  Read More
                </Button>
              </React.Fragment>
            }
            placement="left"
            open={isTooltipOpen}
            onClose={() => setIsTooltipOpen(false)}
            onOpen={() => setIsTooltipOpen(true)}
          > 
            <Fab
              size="large"
              aria-label="scroll back to top"
              onClick={handleFabClick}
              sx={{
                position: "fixed",
                bottom: 86,
                right: 16,
                backgroundColor: theme.palette.primary.main,
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                  transform: "scale(1.2)",
                },
              }}
            >
              <img
                src={eBeam}
                alt="eBeam"
                style={{
                  width: "35px",
                  height: "30px",
                  // width: isHovered ? "40px" : "35px",
                  // height: isHovered ? "40px" : "30px",
                  alignItems: "center",
                  transition: "all 0.3s ease-in-out",
                }}
              />
            </Fab>
          </HtmlTooltip>*/}
          <Fab
            size="large"
            aria-label="Navigate to WhatsApp"
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
              backgroundColor: "#00db0f",
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                backgroundColor: "#00db0f",
                transform: "scale(1.2)",
              },
            }}
            href="//api.whatsapp.com/send?phone=7055521808"
            target="_blank"
          >
            <WhatsApp sx={{ color: "white", fontSize: 35 }} />
          </Fab>

          {/* Scroll-to-Top FAB */}
          <ScrollTop {...props}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Fab
                size="large"
                aria-label="scroll back to top"
                sx={{
                  backgroundColor: "#434343",
                  "&:hover": {
                    backgroundColor: "#434343",
                  },
                  "&:hover svg": {
                    color: theme.palette.primary.main,
                  },
                  position: "fixed",
                  bottom: 90,
                  right: 16,
                }}
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                <ArrowUpward sx={{ color: "white" }} />
              </Fab>
            </Box>
          </ScrollTop>
        </div>
      </Box>
    </ThemeProvider>
  );
}

export default App;
