import React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { useState } from "react";
import { Products } from "../content/productContent";

const MCBsPage = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        centered
      >
        <Tab label="Cable 1" />
        <Tab label="Cable 2" />
        <Tab label="Cable 3" />
      </Tabs>
      <Box>
        {currentTab === 0 && <CablePage cable={Products[0].items[0]} />}
        {currentTab === 1 && <CablePage cable={Products[0].items[1]} />}
        {currentTab === 2 && <CablePage cable={Products[0].items[2]} />}
      </Box>
    </Box>
  );
};

const CablePage = ({ cable }) => (
  <Box>
    <h1>{cable.title}</h1>
    <p>{cable.description}</p>
    {/* Add more details as needed */}
  </Box>
);

export default MCBsPage;
