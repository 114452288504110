import React, { useState, useEffect, memo } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  MenuItem,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Box,
  useMediaQuery,
} from "@mui/material";
import { KeyboardArrowRight, MenuOutlined } from "@mui/icons-material";
import Logo from "../img/logo.png";
import theme from "../theme/index";
import { styled } from "@mui/material/styles";
import { Products } from "../content/productContent";
import { useNavigate } from "react-router-dom";
import DrawerNavBar from "../component/DrawerNavBar";
import Dropdown from "../component/Dropdown";

const StyledAppBar = styled(AppBar)(({ theme, scrolled }) => ({
  transition: "background-color 0.5s ease",
  backgroundColor: scrolled ? "black" : "transparent",
  boxShadow: scrolled ? undefined : "none",
}));

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const location = useLocation();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleScroll = () => {
    let threshold;
    if (isSmallScreen) {
      threshold = 15; // threshold for small screens
    } // threshold for medium screens
    else {
      threshold = 100; // default threshold for large screens
    }

    if (window.scrollY > threshold) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const lcaseN = location.pathname.toLowerCase().trim();
  const navigate = useNavigate();

  const handleClickProductSubMenuItem = (item, category) => {
    navigate("product", { state: { item, category } });
    setDrawerOpen(false);
  };

  const handleInvestorNavigate = (link) => {
    navigate(link);
    setDrawerOpen(false);
  };

  const handleAboutusNavigate = (id) => {
    if (lcaseN.includes("about-us")) {
      scrollToElement(id);
    } else {
      navigate("about-us", { state: { scrollToId: id } });
      setTimeout(() => {
        scrollToElement(id);
      }, 500);
    }
  };

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      if (id === "aboutUs-history") {
        window.scrollTo(0, 0);
      } else {
        const yOffset = -120;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  };

  const AboutUsMenuItems = [
    { title: "History", id: "aboutUs-history" },
    { title: "Company", id: "aboutUs-company" },
    { title: "Our Manufacturing Excellence", id: "aboutus-companyvideo" },
    { title: "We Support", id: "aboutUs-services" },
    { title: "Vision & Mission", id: "aboutUs-vision" },
    { title: "Our Leadership", id: "aboutus-ourLeadership" },
    { title: "Our Policy", id: "aboutUs-policy" },
    { title: "CSR", id: "aboutUs-csr" },
    { title: "Certifications", id: "aboutUs-certifications" },
    { title: "Our clientele", id: "aboutUs-ourClients" },
  ];

  const InvestorMenuItems = [
    {
      title: "Disclosure - Regulation 46 of the SEBI",
      path: "/investor/sebi46",
    },
    { title: "Policies & Program", path: "/investor/policies" },
    { title: "Announcements", path: "/investor/announcements" },
    { title: "IPO", path: "/investor/ipo" },
    {
      title: "Preferential Issue of Equity Shares",
      path: "/investor/preferential-issue",
    },
    { title: "Investor Grievance", path: "/investor/investor-grievance" },
    {
      title: "Details Of Registrar and Share Transfer Agents",
      path: "/investor/details-of-registrar",
    },
  ];
  return (
    <StyledAppBar
      position="fixed"
      // sx={{ backgroundColor: "transparent", boxShadow: "none", mt: 1 }}
      scrolled={isScrolled ? "true" : undefined}
    >
      <Container maxWidth="lg">
        <Toolbar
          id="back-to-top-anchor"
          disableGutters
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <img
            src={Logo}
            loading="eager"
            alt="logo"
            style={{
              transition: "height 0.5s ease",
              height: isScrolled ? "110px" : "140px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          />

          {/* Navigation links for medium and larger screens */}
          <Box sx={{ display: { xs: "none", md: "flex" } }} ml={4}>
            <Typography
              variant="h5"
              mx={2}
              component={Link} // Use Link component for navigation
              to={"/"}
              sx={{
                my: 3,
                color: `${theme.palette.textPrimary.main}`,
                display: "block",
                "&:hover": { color: `${theme.palette.primary.main}` },
                position: "relative",
                textDecoration: "none",
              }}
            >
              Home
              {/* Underline */}
              <span
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: -6, // Adjust this value to control the gap between text and underline
                  width:
                    lcaseN === "/" || lcaseN.includes("Home") ? "100%" : "0",
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                }}
              ></span>
            </Typography>
            <Dropdown title={"About Us"} Path={"about-us"}>
              {AboutUsMenuItems.map((item) => (
                <MenuItem
                  key={item.id}
                  sx={{
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                    },
                  }}
                  onClick={() => handleAboutusNavigate(item.id)}
                >
                  {item.title}
                </MenuItem>
              ))}
            </Dropdown>

            <Dropdown title={"Products"} Path={"product"}>
              <MenuItem
                sx={{
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
                onClick={() => navigate("/mcbs")}
              >
                MCBs
              </MenuItem>
              <MenuItem
                sx={{
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
                onClick={() => navigate("/ebeam")}
              >
                e-Beam
              </MenuItem>
              {Products.map((product, i) => (
                <Dropdown key={i} right title={product.category}>
                  {product.items.map((item, j) => (
                    <MenuItem
                      key={j}
                      sx={{
                        "&:hover": {
                          backgroundColor: theme.palette.primary.main,
                        },
                      }}
                      onClick={() =>
                        handleClickProductSubMenuItem(item, product.category)
                      }
                    >
                      {item.title}
                      {item.length > 0 ? <KeyboardArrowRight /> : null}
                    </MenuItem>
                  ))}
                </Dropdown>
              ))}
            </Dropdown>

            <Dropdown title={"Investor"} Path={"investor"}>
              {InvestorMenuItems.map((item, index) => (
                <MenuItem
                  key={index}
                  sx={{
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                    },
                  }}
                  onClick={() => handleInvestorNavigate(item.path)}
                >
                  {item.title}
                </MenuItem>
              ))}
            </Dropdown>
            <Typography
              // key={page}
              variant="h5"
              mx={2}
              component={Link} // Use Link component for navigation
              to={"careers"}
              sx={{
                my: 3,
                color: `${theme.palette.textPrimary.main}`,
                display: "block",
                "&:hover": { color: `${theme.palette.primary.main}` },
                position: "relative",
              }}
              style={{
                textDecoration: "none", // Remove underline from default
              }}
            >
              Careers
              {/* Underline */}
              <span
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: -6, // Adjust this value to control the gap between text and underline
                  width: lcaseN.includes("careers") ? "100%" : "0",
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                }}
              ></span>
            </Typography>
            <Typography
              // key={page}
              variant="h5"
              mx={2}
              component={Link} // Use Link component for navigation
              to={"contact-us"}
              sx={{
                my: 3,
                color: `${theme.palette.textPrimary.main}`,
                display: "block",
                "&:hover": { color: `${theme.palette.primary.main}` },
                position: "relative",
                textDecoration: "none",
              }}
            >
              Contact Us
              {/* Underline */}
              <span
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: -6, // Adjust this value to control the gap between text and underline
                  width: lcaseN.includes("/contact-us") ? "100%" : "0",
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                }}
              ></span>
            </Typography>
            {/* );
            })} */}
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          > */}
          {/* Language selector */}
          {/* <LanguageSelector /> */}
          <IconButton
            size="large"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{
              display: { md: "none", color: theme.palette.textPrimary.main },
            }}
          >
            <MenuOutlined />
          </IconButton>

          {/* User settings */}
          {/* <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
          <IconButton sx={{ p: 0 }}>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
          </IconButton>
          </Tooltip>
        </Box> */}
          {/* </Box> */}
        </Toolbar>

        {/* Responsive drawer for small screens */}
        <DrawerNavBar toggleDrawer={toggleDrawer} drawerOpen={drawerOpen} />
      </Container>
    </StyledAppBar>
  );
};

export default memo(Navbar);
